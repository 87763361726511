import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { Button } from '../../common/Button/Button';
import './AuthenticatedNavigation.css';

export const AuthenticatedNavigation = () => {
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  return (
    <nav className='auth-nav'>
      <div className='container auth-nav__content'>
        <a href='/' className='auth-nav__brand'>
          LookAround
        </a>

        <button className='auth-nav__menu-toggle' onClick={() => setIsMenuOpen(!isMenuOpen)}>
          ☰
        </button>

        <div className='auth-nav__menu'>
          <span className='auth-nav__welcome'>Welcome, {user?.name || 'Creator'}</span>
          <Button variant='light' onClick={logout}>
            Sign Out
          </Button>
        </div>

        {isMenuOpen && (
          <div className='auth-nav__mobile-menu'>
            <button className='auth-nav__mobile-close' onClick={() => setIsMenuOpen(false)}>
              ✕
            </button>
            <span className='auth-nav__welcome'>Welcome, {user?.name || 'Creator'}</span>
            <Button variant='light' onClick={logout}>
              Sign Out
            </Button>
          </div>
        )}
      </div>
    </nav>
  );
};
