// LocalGuideDetailsPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLocalGuideDetails } from '../../../api/guides/LocalGuideDetails';
import { LocalGuideDetailsView } from '../../../components/guides/GuideDetails/GuideDetails';
import { LoadingScreen } from '../../../components/common/LoadingScreen/LoadingScreen';
import type { LocalGuideDetails } from '../../../domain/guides/LocalGuideDetails';
import { ErrorScreen } from '../../../components/common/ErrorScreen/ErrorScreen';

export const LocalGuideDetailsPage = () => {
  const { guideId = '' } = useParams<{ guideId: string }>();
  const [guideData, setGuideData] = useState<LocalGuideDetails | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGuideDetails = async () => {
      try {
        const response = await getLocalGuideDetails(guideId);
        if (response.isOk && response.guide) {
          setGuideData(response.guide);
        } else {
          setError('Guide not found');
        }
      } catch (err) {
        setError('Failed to load guide details');
      } finally {
        setLoading(false);
      }
    };

    fetchGuideDetails();
  }, [guideId]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (guideData) {
    return <LocalGuideDetailsView guide={guideData} />;
  } else {
    return <ErrorScreen message={error || 'Guide not found'} />;
  }
};
