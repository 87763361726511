import React from 'react';
import './Features.css';

const FEATURES = [
  {
    title: 'Explore',
    description: 'Discover hidden spots, untold stories and access guides about a broad range of topics.',
  },
  {
    title: 'Learn',
    description: 'Play location specific trivia and dive deeper into topics you find interesting.',
  },
  {
    title: 'Share and monetize',
    description: 'Become the local storyteller/ guide. Share your perspective and earn money and cred.',
  },
];

export const Features = () => (
  <section className='features'>
    <div className='container'>
      <h2 className='features-title'>Every Walk can be a Memorable Experience</h2>
      <div className='features-grid'>
        {FEATURES.map((feature, index) => (
          <div key={index} className='feature-card'>
            <h3 className='feature-title'>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);
