import React from 'react';
import { Upload, X, Loader, Video } from 'lucide-react';
import { useMediaUpload } from '../../../../hooks/useMediaUpload';
import { VideoResource } from '../../../../types/media';
import './VideoPlayer.css';
import '../SharedStyles.css';

interface VideoPlayerProps {
  id: string;
  videoUrl?: string;
  thumbnailUrl?: string;
  title?: string;
  isEditing?: boolean;
  onChange?: (media: VideoResource | null) => void;
  required?: boolean;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  id,
  videoUrl,
  thumbnailUrl,
  title,
  isEditing = false,
  onChange,
  required = false,
}) => {
  const {
    isUploading,
    isDragging,
    error,
    fileInputRef,
    setIsDragging,
    openFileSelector,
    handleDrop,
    handleChange,
    acceptedFormats,
  } = useMediaUpload<'video'>({
    type: 'video',
    onUpload: onChange!,
  });

  if (!videoUrl && isEditing) {
    return (
      <div className={'video-player'} id={`video-player-${id}`}>
        <input
          ref={fileInputRef}
          type='file'
          accept={acceptedFormats}
          onChange={handleChange}
          className='video-player__input-hidden'
          id={`video-input-${id}`}
          aria-label={`Upload video ${title || ''}`}
        />

        <div
          className={`media-dropzone ${isDragging ? 'media-dropzone--active' : ''} video-player__dropzone`}
          onDragOver={e => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
          onClick={openFileSelector}
          role='button'
          tabIndex={0}
          aria-labelledby={`video-player-title-${id}`}
        >
          {isUploading ? (
            <div className='media-dropzone__uploading'>
              <Loader className='media-dropzone__spinner' />
              <span>Uploading...</span>
            </div>
          ) : (
            <>
              <div className='media-dropzone__icon'>
                <Video size={48} />
              </div>
              <div className='media-dropzone__text'>
                <span>Drag and drop or click to select a video file</span>
              </div>
            </>
          )}
        </div>
        {error && <div className='media-error video-player__error'>{error}</div>}
      </div>
    );
  }

  return (
    <div className='video-player' id={`video-player-${id}`}>
      {title && <h4 className='video-player__title'>{title}</h4>}
      <video
        controls
        src={videoUrl}
        poster={thumbnailUrl}
        className='video-player__video'
        id={`video-controls-${id}`}
      />
      {isEditing && (
        <button
          onClick={openFileSelector}
          className='video-player__replace'
          id={`video-replace-${id}`}
          aria-label={`Replace video ${title || ''}`}
        >
          <Upload size={16} />
          <span>Replace video</span>
        </button>
      )}
      {!required && isEditing && (
        <button
          onClick={() => onChange?.(null)}
          className='video-player__remove'
          id={`video-remove-${id}`}
          aria-label='Remove video'
        >
          <X size={20} />
        </button>
      )}
      {error && (
        <div className='video-player__error' id={`video-error-${id}`}>
          {error}
        </div>
      )}
    </div>
  );
};
