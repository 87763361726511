import { asyncPostRequest } from './requests';

export const signInWithGoogle = async token => {
  return asyncPostRequest('/auth/sign_in_with_google', { token }, true);
};

export const signInWithToken = async () => {
  return asyncPostRequest('/auth/sign_in_with_token', {}, true);
};

export const signout = async () => {
  return asyncPostRequest('/auth/sign_out', {}, true);
};

export const sendEmail = async () => {
  return asyncPostRequest('/auth/test_email', {}, true);
};

export const runTestCode = async () => {
  return asyncPostRequest('/api/living-on/test-run', {}, true);
};
