import { asyncPatchRequest, asyncPostRequest } from '../../../api/requests';

export const guideDetailsForCreator = async ({ overview_id }) => {
  return await asyncPostRequest('/api/creators/guide_details', { overview_id }, true);
};

export const updateGuideForCreator = async ({ updated_guide }) => {
  return await asyncPatchRequest('/api/creators/guide_details', { updated_guide }, true);
};

export const guideListForCreator = async () => {
  return await asyncPostRequest('/api/creators/local_guides', {}, true);
};
