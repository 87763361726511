import React from 'react';
import './CreatorProcess.css';

const PROCESS_STEPS = [
  {
    step: 1,
    title: 'Sign Up',
    description: 'Fill out the form below and tell us about your interests.',
  },
  {
    step: 2,
    title: 'Get Approved',
    description: 'Our team will review your interests and provide access to the creator tools.',
  },
  {
    step: 3,
    title: 'Create Content',
    description: 'Start creating location-based stories, guides, and experiences for the LookAround community.',
  },
  {
    step: 4,
    title: 'Share & Earn',
    description: 'Get paid as users access your content and build your reputation as an expert storyteller.',
  },
];

export const CreatorProcess = () => (
  <section className='process'>
    <div className='container'>
      <h2 className='process-title'>How It Works</h2>
      <div className='process-grid'>
        {PROCESS_STEPS.map((process, index) => (
          <div key={index} className='process-card'>
            <div className='number-circle'>{process.step}</div>
            <h3 className='process-step-title'>{process.title}</h3>
            <p className='process-description'>{process.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);
