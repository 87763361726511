import React from 'react';
import { Navigation } from '../../components/layout/Navigation/Navigation';
import { Footer } from '../../components/layout/Footer/Footer';
import './FeedbackThanks.css';

export const FeedbackThanks = () => {
  return (
    <div className='feedback-thanks-page'>
      <Navigation />

      <main className='feedback-thanks-content'>
        <div className='container'>
          <div className='feedback-thanks-card'>
            <h1 className='feedback-thanks-title'>Thank You!</h1>
            <p className='feedback-thanks-message'>
              For other questions, reach us at <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a>.
            </p>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};
