// api/guides/LocalGuideDetails.ts
import { asyncGetRequest } from '../../../api/requests';
import type { LocalGuideDetailsResponse } from '../../domain/guides/LocalGuideDetails';

export const getLocalGuideDetails = async (guideId: string): Promise<LocalGuideDetailsResponse> => {
  const response = (await asyncGetRequest(
    `/api/app_content/public_local_guide/${guideId}`
  )) as LocalGuideDetailsResponse;

  if (!response.isOk) {
    return {
      isOk: false,
      error: response.error || 'Failed to fetch guide details',
    };
  }

  return response;
};
