import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { APP_STORE_URL } from '../../../constants/theme';
import { Button } from '../../common/Button/Button';
import './Navigation.css';

export const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  const navLinks = [
    { path: '/', label: 'Home' },
    { path: '/creators', label: 'Creators' },
    { path: '/guides', label: 'Guides' },
  ];
  const visibleLinks = navLinks.filter(link => link.path !== location.pathname);

  return (
    <nav className='navigation'>
      <div className='container navigation-content'>
        <a href='/' className='navigation-brand'>
          LookAround
        </a>

        <button className='menu-toggle' onClick={() => setIsMenuOpen(!isMenuOpen)}>
          ☰
        </button>

        <div className='nav-menu'>
          {visibleLinks.map(link => (
            <a key={link.path} href={link.path} className='navigation-link'>
              {link.label}
            </a>
          ))}
          <Button variant='light' href={APP_STORE_URL} target='_blank' rel='noopener noreferrer'>
            Download App
          </Button>
        </div>

        {isMenuOpen && (
          <div className='mobile-menu active'>
            <button className='mobile-close' onClick={() => setIsMenuOpen(false)}>
              ✕
            </button>
            {visibleLinks.map(link => (
              <a key={link.path} href={link.path} className='navigation-link'>
                {link.label}
              </a>
            ))}
            <Button variant='light' href={APP_STORE_URL} target='_blank' rel='noopener noreferrer'>
              Download App
            </Button>
          </div>
        )}
      </div>
    </nav>
  );
};
