import React, { useEffect, useState } from 'react';
import { Plus } from 'lucide-react';
import './GuideOverviewEdit.css';
import { MediaUploader } from '../shared/media/MediaUploader';
import { CaptionedImage, AudioResource, VideoResource } from '../../../../types/media';
import { GuideDetails } from '../../../../types/guide';
import { AudioPlayer } from '../../../common/media/AudioPlayer/AudioPlayer';

interface GuideOverviewEditProps {
  guide: GuideDetails;
  isEditing: boolean;
  onUpdateTitle: (title: string) => void;
  onUpdateDescription: (description: string) => void;
  onUpdateHeaderImage: (image: CaptionedImage | null) => void;
  onUpdateAudio: (audio: AudioResource | null) => void;
  onUpdateVideo: (video: VideoResource | null) => void;
  onAddStop: () => void;
  onSave: () => void;
  onCancel: () => void;
}

export const GuideOverviewEdit: React.FC<GuideOverviewEditProps> = ({
  guide,
  isEditing,
  onUpdateTitle,
  onUpdateDescription,
  onUpdateHeaderImage,
  onUpdateAudio,
  onUpdateVideo,
  onAddStop,
  onSave,
  onCancel,
}) => {
  const [localTitle, setLocalTitle] = useState(guide.overview.title);
  const [localDescription, setLocalDescription] = useState(guide.overview.description);

  // Update local state when props change or editing mode changes
  useEffect(() => {
    if (isEditing) {
      setLocalTitle(guide.overview.title);
      setLocalDescription(guide.overview.description);
    }
  }, [isEditing, guide.overview.title, guide.overview.description]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalTitle(e.target.value);
    onUpdateTitle(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalDescription(e.target.value);
    onUpdateDescription(e.target.value); // Remove this if you want to update only on save
  };

  const handleReplaceAudio = () => {
    // This will be handled by MediaUploader internally
  };

  const renderTitle = () => {
    if (isEditing) {
      return (
        <div className='guide-overview__editable-field'>
          <input
            type='text'
            value={localTitle}
            onChange={handleTitleChange}
            className='guide-overview__editable-input'
            placeholder='Enter guide title'
          />
        </div>
      );
    }
    return <h2 className='guide-overview__title'>{guide.overview.title}</h2>;
  };

  const renderDescription = () => {
    if (isEditing) {
      return (
        <div className='guide-overview__editable-field'>
          <textarea
            value={localDescription}
            onChange={handleDescriptionChange}
            className='guide-overview__editable-textarea'
            placeholder='Enter guide description'
          />
        </div>
      );
    }
    return <p className='guide-overview__description'>{guide.overview.description}</p>;
  };

  return (
    <div className='guide-overview'>
      {renderTitle()}
      {renderDescription()}

      <div className='guide-overview__media'>
        {isEditing ? (
          <>
            <MediaUploader
              id={`overview-image-${guide.overview.id}`}
              type='image'
              value={guide.overview.headerImage || undefined}
              onChange={onUpdateHeaderImage}
              required={true}
            />

            <MediaUploader
              id={`overview-audio-${guide.overview.id}`}
              type='audio'
              value={guide.overview.audio || undefined}
              onChange={onUpdateAudio}
            />

            <MediaUploader
              id={`overview-video-${guide.overview.id}`}
              type='video'
              value={guide.overview.video || undefined}
              onChange={onUpdateVideo}
            />
          </>
        ) : (
          <>
            {guide.overview.headerImage && (
              <img
                src={guide.overview.headerImage.imageUrl}
                alt={guide.overview.headerImage.caption || guide.overview.title}
                className='guide-overview__header-image'
              />
            )}
            {guide.overview.audio && (
              <AudioPlayer
                id={`overview-audio-${guide.overview.id}`}
                title={guide.overview.audio.title}
                audioUrl={guide.overview.audio.audioFileUrl}
                isEditing={false}
              />
            )}
            {guide.overview.video && (
              <div className='guide-overview__video'>
                <video
                  controls
                  src={guide.overview.video.videoFileUrl}
                  poster={guide.overview.video.thumbnailImageUrl}
                />
              </div>
            )}
          </>
        )}
      </div>

      {isEditing && (
        <div className='guide-overview__actions'>
          <button className='button button-light' onClick={onAddStop}>
            <Plus size={20} />
            Add Stop
          </button>
          <div className='guide-overview__edit-actions'>
            <button className='button button-light' onClick={onCancel}>
              Cancel
            </button>
            <button className='button button-primary' onClick={onSave}>
              Save Changes
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
