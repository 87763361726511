// components/guides/LocalGuideDetailsView.js
import React from 'react';
import { Navigation } from '../../../components/layout/Navigation/Navigation';
import { Footer } from '../../../components/layout/Footer/Footer';
import { Button } from '../../../components/common/Button/Button';
import { APP_STORE_URL } from '../../../constants/theme';
import { TourFeatureCards } from '../../../components/sections/TourDetails/TourFeatureCards/TourFeatureCards';
import type { LocalGuideDetails } from '../../../domain/guides/LocalGuideDetails';
import './GuideDetails.css';

type LocalGuideDetailsViewProps = {
  guide: LocalGuideDetails;
};

export const LocalGuideDetailsView: React.FC<LocalGuideDetailsViewProps> = ({ guide }) => {
  const { name, heroImageUrl, description, gallery, creator, testimonials } = guide;

  return (
    <div className='guide-details-page'>
      <Navigation />

      <main>
        <section className='guide-details-hero'>
          <img className='guide-details-hero__background' src={heroImageUrl} alt='' />
          <div className='guide-details-hero__content'>
            <h1 className='guide-details-hero__title'>{name}</h1>
          </div>
        </section>

        <section className='guide-details__container'>
          <div className='guide-details__content-grid'>
            <div>
              <div className='guide-details-about'>
                <h2 className='guide-details-about__title'>About This Guide</h2>
                {description.map((paragraph, index) => (
                  <p key={index} className='guide-details-about__description'>
                    {paragraph}
                  </p>
                ))}
                <div className='guide-details-about__image-grid'>
                  {gallery.map((image, index) => (
                    <img key={index} className='guide-details-about__image' src={image.imageUrl} alt={image.caption} />
                  ))}
                </div>
              </div>

              <div className='guide-details-testimonials'>
                <h2 className='guide-details-testimonials__title'>What Guests Are Saying</h2>
                <div className='guide-details-testimonials__grid'>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className='guide-details-testimonials__card'>
                      <p className='guide-details-testimonials__text'>"{testimonial.text}"</p>
                      <div className='guide-details-testimonials__author'>
                        <p className='guide-details-testimonials__author-name'>{testimonial.author}</p>
                        <p className='guide-details-testimonials__author-date'>{testimonial.date}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <aside className='guide-details-sidebar'>
              <div className='guide-details-creator'>
                <div className='guide-details-creator__header'>
                  <img
                    className='guide-details-creator__image'
                    src={creator.person.profilePic.imageUrl}
                    alt={creator.person.profilePic.caption}
                  />
                  <div>
                    <h3 className='guide-details-creator__title'>Your Guide: {creator.person.firstName}</h3>
                    <p className='guide-details-creator__credentials'>{creator.credentials}</p>
                  </div>
                </div>
                <p className='guide-details-creator__bio'>{creator.bio}</p>
              </div>

              <div className='guide-details-cta'>
                <h3 className='guide-details-cta__title'>Experience This Guide</h3>
                <p className='guide-details-cta__description'>
                  Download the LookAround app to access this and other amazing guides of San Francisco.
                </p>
                <Button variant='primary' href={APP_STORE_URL}>
                  Download App
                </Button>
              </div>

              <TourFeatureCards />
            </aside>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};
