import React from 'react';
import { CaptionedImage, AudioResource, VideoResource } from '../../../../../types/media';
import { MediaType } from './MediaConfig';
import { AudioPlayer } from '../../../../common/media/AudioPlayer/AudioPlayer';
import { ImageViewer } from '../../../../common/media/ImageViewer/ImageViewer';
import { VideoPlayer } from '../../../../common/media/VideoPlayer/VideoPlayer';
import './MediaUploader.css';

export type MediaTypeMap = {
  image: CaptionedImage;
  audio: AudioResource;
  video: VideoResource;
};

interface MediaUploaderProps<T extends MediaType> {
  id: string;
  type: T;
  value?: MediaTypeMap[T];
  onChange: (media: MediaTypeMap[T] | null) => void;
  required?: boolean;
  className?: string;
}

export const MediaUploader = <T extends MediaType>({
  id,
  type,
  value,
  onChange,
  required = false,
  className = '',
}: MediaUploaderProps<T>) => {
  switch (type) {
    case 'image':
      return (
        <ImageViewer
          id={id}
          imageUrl={value ? (value as CaptionedImage).imageUrl : undefined}
          caption={value ? (value as CaptionedImage).caption : undefined}
          isEditing={true}
          onChange={onChange as (media: CaptionedImage | null) => void}
        />
      );

    case 'audio':
      return (
        <AudioPlayer
          id={id}
          title={value ? (value as AudioResource).title : undefined}
          audioUrl={value ? (value as AudioResource).audioFileUrl : undefined}
          isEditing={true}
          onChange={onChange as (media: AudioResource | null) => void}
          required={required}
        />
      );

    case 'video':
      return (
        <VideoPlayer
          id={id}
          videoUrl={value ? (value as VideoResource).videoFileUrl : undefined}
          thumbnailUrl={value ? (value as VideoResource).thumbnailImageUrl : undefined}
          title={value ? (value as VideoResource).title : undefined}
          isEditing={true}
          onChange={onChange as (media: VideoResource | null) => void}
          required={required}
        />
      );
    default:
      throw new Error(`Unsupported media type: ${type}`);
  }
};
