import React from 'react';
import { Upload, Loader, Image } from 'lucide-react';
import { useMediaUpload } from '../../../../hooks/useMediaUpload';
import './ImageViewer.css';
import { CaptionedImage } from '../../../../types/media';
import '../SharedStyles.css';

interface ImageViewerProps {
  id: string;
  imageUrl?: string;
  caption?: string;
  className?: string;
  isEditing?: boolean;
  onChange?: (media: CaptionedImage | null) => void;
}

export const ImageViewer: React.FC<ImageViewerProps> = ({
  id,
  imageUrl,
  caption,
  className = '',
  isEditing = false,
  onChange,
}) => {
  const {
    isUploading,
    isDragging,
    error,
    fileInputRef,
    setIsDragging,
    openFileSelector,
    handleDrop,
    handleChange,
    acceptedFormats,
  } = useMediaUpload<'image'>({
    type: 'image',
    onUpload: onChange!,
  });
  return (
    <div className={`image-viewer ${className}`} id={`image-viewer-${id}`}>
      <input
        ref={fileInputRef}
        type='file'
        accept={acceptedFormats}
        onChange={handleChange}
        className='media-dropzone__input-hidden'
        id={`image-input-${id}`}
        aria-label={`Upload image ${caption || ''}`}
      />
      {!imageUrl && isEditing ? (
        <div
          className={`media-dropzone ${isDragging ? 'media-dropzone--active' : ''}`}
          onDragOver={e => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
          onClick={openFileSelector}
          role='button'
          tabIndex={0}
          aria-labelledby={`image-viewer-title-${id}`}
        >
          {isUploading ? (
            <div className='media-dropzone__uploading'>
              <Loader className='media-dropzone__spinner' />
              <span>Uploading...</span>
            </div>
          ) : (
            <>
              <div className='media-dropzone__icon'>
                <Image size={48} />
              </div>
              <div className='media-dropzone__text'>
                <span>Drag and drop or click to select an image</span>
              </div>
            </>
          )}
        </div>
      ) : (
        // Show image preview and replace button when we have an image
        <>
          <div className='image-viewer__preview'>
            <img src={imageUrl} alt={caption || ''} className='image-viewer__image' />
            {caption && <p className='image-viewer__caption'>{caption}</p>}
          </div>

          {isEditing && (
            <button
              onClick={openFileSelector}
              className='image-viewer__replace'
              disabled={isUploading}
              aria-label='Replace image'
            >
              {isUploading ? (
                <Loader className='media-viewer__spinner' />
              ) : (
                <>
                  <Upload size={16} />
                  <span>Replace image</span>
                </>
              )}
            </button>
          )}
        </>
      )}

      {error && <div className='media-viewer__error'>{error}</div>}
    </div>
  );
};
