import React from 'react';
import './CreatorBenefits.css';

const BENEFITS = [
  {
    title: 'Build a spatial storyboard',
    description:
      'Help people discover stories as they move around. Tell diverse stories and connect people to them in the right context.',
  },
  {
    title: 'Reach a broader audience',
    description:
      "Already a guide? Don't be caught up with scheduling tours. LookAround serves tours 24/7 so you can focus on developing great content to increase revenue.",
  },
];

export const CreatorBenefits = () => (
  <section className='benefits'>
    <div className='container'>
      <h2 className='benefits-title'>Why Become a Creator?</h2>
      <div className='benefits-grid'>
        {BENEFITS.map((benefit, index) => (
          <div key={index} className='benefit-card'>
            <h3 className='benefit-title'>{benefit.title}</h3>
            <p className='benefit-description'>{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);
