import React from 'react';
import { BluetoothConnected, Calendar } from 'lucide-react';
import './TourFeatureCards.css';

export const TourFeatureCards = () => {
  return (
    <div className='mb-3'>
      <div className='tour-feature-card mb-2'>
        <div className='card-body d-flex align-items-center'>
          <div className='tour-details-feature-icon-wrapper bg-success bg-opacity-10 rounded-circle me-3'>
            <BluetoothConnected className='tour-details-feature-icon-primary' size={20} />
          </div>
          <div>
            <h6 className='tour-feature-card-title mb-1'>Listen together</h6>
            <p className='tour-feature-card-text d-flex align-items-center mb-0'>
              Buy once, explore together via Bluetooth
            </p>
          </div>
        </div>
      </div>

      <div className='tour-feature-card'>
        <div className='card-body d-flex align-items-center'>
          <div className='tour-details-feature-icon-wrapper bg-success bg-opacity-10 rounded-circle me-3'>
            <Calendar className='tour-details-feature-icon-primary' size={20} />
          </div>
          <div>
            <h6 className='tour-feature-card-title mb-1'>30-day access</h6>
            <p className='tour-feature-card-text mb-0'>Unlimited plays for 30 days after purchase</p>
          </div>
        </div>
      </div>
    </div>
  );
};
