import React, { useState, useEffect } from 'react';
import { Navigation } from '../../components/layout/Navigation/Navigation';
import { Footer } from '../../components/layout/Footer/Footer';
import { GuidesMap } from '../../components/guides/GuidesMap/GuidesMap';
import { GuideCard } from '../../components/guides/GuideCard/GuideCard';
import { getPublicGuides } from '../../api/guides/PublicGuides';
import './GuidesPage.css';

export const GuidesPage = () => {
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await getPublicGuides();
        if (response.isOk) {
          setGuides(response.guides);
        } else {
          setError('Failed to load guides');
        }
      } catch (err) {
        setError('An error occurred while loading guides');
      } finally {
        setLoading(false);
      }
    };

    fetchGuides();
  }, []);

  if (loading) {
    return <div className='guides-loading'>Loading guides...</div>;
  }

  if (error) {
    return <div className='guides-error'>{error}</div>;
  }

  return (
    <div className='guides-page'>
      <Navigation />
      <main>
        <section className='guides-hero'>
          <div className='container'>
            <h1 className='guides-hero__title'>Explore Local Guides</h1>
            <p className='guides-hero__description'>
              Discover unique perspectives and hidden gems through our curated collection of local guides.
            </p>
          </div>
        </section>

        <section className='guides-map-section'>
          <div className='container'>
            <GuidesMap guides={guides} />
          </div>
        </section>

        <section className='guides-grid-section'>
          <div className='container'>
            <div className='guides-grid'>
              {guides.map(guide => (
                <GuideCard key={guide.id} guide={guide} />
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};
