import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NoPage } from './component/static_pages/NoPage';
import { About } from './component/static_pages/About';
import { Homepage } from './component/signin/Homepage';
import { TermsOfService } from './component/static_pages/TermsOfService';
import { EnableLocation } from './component/static_pages/EnableLocation';
import { CreatorsPage } from './v2/pages/creators/CreatorsPage';
import { CreatorsPortalLandingPage } from './v2/pages/creators/portal/landing/CreatorsPortalLandingPage';
import { ProtectedRoute } from './v2/components/auth/ProtectedRoute';
import { AuthProvider } from './v2/context/AuthContext';
import { GuidesPage } from './v2/pages/guides/GuidesPage';
import { LocalGuideDetailsPage } from './v2/pages/guides/details/LocalGuideDetailsPage';
import FeedbackForm from './v2/pages/feedback/EntityFeedbackForm';
import { FeedbackThanks } from './v2/pages/feedback/FeedbackThanks';
import { CreatorDashboard } from './v2/pages/creators/dashboard/CreatorDashboard';

// Component to handle the redirect
const RedirectToFile = ({ filename }: { filename: string }) => {
  useEffect(() => {
    const path = `${process.env.PUBLIC_URL}/files/${filename}`;
    window.location.href = path;
  }, [filename]);

  return null; // This component does not render anything
};

function App() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty array ensures this effect runs only once

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<Homepage />} />
          <Route path='/about' element={<About />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/enable-location' element={<EnableLocation />} />
          <Route path='/map' element={<Homepage startingPage='mapTool' />} />
          <Route path='/creators' element={<CreatorsPage />} />
          <Route path='/guides' element={<GuidesPage />} />
          <Route path='/guides/:guideId' element={<LocalGuideDetailsPage />} />
          <Route path='/creators-portal' element={<CreatorsPortalLandingPage />} />
          <Route
            path='/creators-portal/dashboard'
            element={
              <ProtectedRoute>
                <CreatorDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/.well-known/apple-developer-merchantid-domain-association'
            element={<RedirectToFile filename='apple-developer-merchantid-domain-association' />}
          />
          <Route path='/user_feedback/rate_experience/:token' element={<FeedbackForm />} />
          <Route path='/feedback/thank-you' element={<FeedbackThanks />} />
          <Route path='/*' element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
