import React, { useState } from 'react';
import { ChevronDown, GripVertical, Trash2 } from 'lucide-react';
import { Draggable } from '@hello-pangea/dnd';
import { EditableText } from '../shared/EditableText';
import './GuideStopEdit.css';
import { GuideStop, StopDetails, StopSection } from '../../../../types/guide';
import { MediaEditor } from '../shared/MediaEditor';
import { StopSectionEditor } from './StopSectionEditor';
import { LocationEditor } from './LocationEditor';
import StopContentDetails from '../overview/StopContentDetails';
import { AudioPlayer } from '../../../common/media/AudioPlayer/AudioPlayer';

interface GuideStopEditProps {
  stop: GuideStop;
  details?: StopDetails;
  index: number;
  isEditing: boolean;
  onUpdate: (stopId: string, changes: Partial<GuideStop>) => void;
  onUpdateDetails?: (stopId: string, changes: Partial<StopDetails>) => void;
  onDelete: (stopId: string) => void;
}

export const GuideStopEdit: React.FC<GuideStopEditProps> = ({
  stop,
  details,
  index,
  isEditing,
  onUpdate,
  onUpdateDetails,
  onDelete,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeSection, setActiveSection] = useState<number | null>(null);

  const toggleExpand = (e: React.MouseEvent) => {
    if (!(e.target as Element).closest('.guide-stop__drag-handle')) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleUpdateSection = (index: number, updates: Partial<StopSection>) => {
    if (!details || !onUpdateDetails) return;

    const updatedSections = [...details.sections];
    updatedSections[index] = { ...updatedSections[index], ...updates };

    onUpdateDetails(stop.id, { sections: updatedSections });
  };

  const addNewSection = () => {
    if (!details || !onUpdateDetails) return;

    const newSection: StopSection = {
      title: '',
      paragraph: '',
    };

    onUpdateDetails(stop.id, {
      sections: [...details.sections, newSection],
    });
  };

  return (
    <Draggable draggableId={stop.id} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} className='guide-stop'>
          <div className='guide-stop__header' onClick={toggleExpand}>
            <div {...provided.dragHandleProps} className='guide-stop__drag-handle'>
              <GripVertical size={20} />
            </div>

            {isEditing ? (
              <EditableText
                value={stop.title}
                onChange={value => onUpdate(stop.id, { title: value })}
                placeholder='Stop title'
              />
            ) : (
              <h3 className='guide-stop__title'>{stop.title}</h3>
            )}

            <div className='guide-stop__actions'>
              {isEditing && (
                <button className='guide-stop__delete' onClick={() => onDelete(stop.id)}>
                  <Trash2 size={20} />
                </button>
              )}
              <ChevronDown
                size={20}
                className={`guide-stop__chevron ${isExpanded ? 'guide-stop__chevron--expanded' : ''}`}
              />
            </div>
          </div>

          {isExpanded && (
            <div className='guide-stop__content'>
              {isEditing ? (
                <>
                  <EditableText
                    value={stop.description}
                    onChange={value => onUpdate(stop.id, { description: value })}
                    multiline
                    placeholder='Stop description'
                  />

                  <StopContentDetails
                    stop={stop}
                    details={details}
                    isEditing={isEditing}
                    onUpdate={(changes: Partial<StopDetails>) => onUpdateDetails?.(stop.id, changes)}
                  />

                  <LocationEditor
                    latitude={stop.latitude}
                    longitude={stop.longitude}
                    onChange={(lat, lng) => onUpdate(stop.id, { latitude: lat, longitude: lng })}
                  />

                  <MediaEditor type='image' current={stop.image} />

                  <MediaEditor type='audio' current={stop.audio} />

                  <MediaEditor type='video' current={stop.video} />

                  {details && (
                    <div className='guide-stop__sections'>
                      <h4 className='guide-stop__sections-title'>Content Sections</h4>
                      {details.sections.map((section, idx) => (
                        <StopSectionEditor
                          key={idx}
                          section={section}
                          isActive={activeSection === idx}
                          onActivate={() => setActiveSection(idx)}
                          onUpdate={updates => handleUpdateSection(idx, updates)}
                        />
                      ))}
                      <button className='button button-light' onClick={addNewSection}>
                        Add Section
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <p className='guide-stop__description'>{stop.description}</p>
                  <StopContentDetails
                    stop={stop}
                    details={details}
                    isEditing={isEditing}
                    onUpdate={(changes: Partial<StopDetails>) => onUpdateDetails?.(stop.id, changes)}
                  />
                  {stop.image && (
                    <div className='guide-stop__image-container'>
                      <img
                        src={stop.image.imageUrl}
                        alt={stop.image.caption || stop.title}
                        className='guide-stop__image'
                      />
                      {stop.image.caption && <p className='guide-stop__image-caption'>{stop.image.caption}</p>}
                    </div>
                  )}
                  {stop.audio && (
                    <AudioPlayer
                      id={`stop-audio-${stop.id}`}
                      title={stop.audio.title}
                      audioUrl={stop.audio.audioFileUrl}
                      isEditing={false}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
