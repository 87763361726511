import React, { useState } from 'react';
import { MapPin } from 'lucide-react';
import './LocationEditor.css';

interface LocationEditorProps {
  latitude?: number;
  longitude?: number;
  onChange: (lat: number, lng: number) => void;
}

export const LocationEditor: React.FC<LocationEditorProps> = ({ latitude, longitude, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localLat, setLocalLat] = useState(latitude?.toString() || '');
  const [localLng, setLocalLng] = useState(longitude?.toString() || '');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const lat = parseFloat(localLat);
    const lng = parseFloat(localLng);

    if (!isNaN(lat) && !isNaN(lng)) {
      onChange(lat, lng);
      setIsEditing(false);
    }
  };

  return (
    <div className='location-editor'>
      <div className='location-editor__header'>
        <h4 className='location-editor__title'>Location</h4>
        <button className='location-editor__toggle' onClick={() => setIsEditing(!isEditing)} type='button'>
          <MapPin size={20} />
          {latitude && longitude ? 'Update Location' : 'Set Location'}
        </button>
      </div>

      {latitude && longitude && !isEditing && (
        <div className='location-editor__preview'>
          <p className='location-editor__coordinates'>
            {latitude.toFixed(6)}, {longitude.toFixed(6)}
          </p>
        </div>
      )}

      {isEditing && (
        <form className='location-editor__form' onSubmit={handleSubmit}>
          <div className='location-editor__input-group'>
            <div className='location-editor__field'>
              <label htmlFor='latitude'>Latitude</label>
              <input
                id='latitude'
                type='number'
                step='0.000001'
                value={localLat}
                onChange={e => setLocalLat(e.target.value)}
                className='location-editor__input'
                placeholder='Enter latitude'
              />
            </div>
            <div className='location-editor__field'>
              <label htmlFor='longitude'>Longitude</label>
              <input
                id='longitude'
                type='number'
                step='0.000001'
                value={localLng}
                onChange={e => setLocalLng(e.target.value)}
                className='location-editor__input'
                placeholder='Enter longitude'
              />
            </div>
          </div>
          <div className='location-editor__actions'>
            <button type='button' className='button button-light' onClick={() => setIsEditing(false)}>
              Cancel
            </button>
            <button type='submit' className='button button-primary'>
              Save Location
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
