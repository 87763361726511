import React from 'react';
import { Link } from 'react-router-dom';
import './GuideCard.css';

export const GuideCard = ({ guide }) => {
  const formattedPrice = (guide.priceCents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Link to={`/guides/${guide.urlSlug || guide.id}`} className='guide-card-link'>
      <div className='guide-card'>
        <div className='guide-card__image-container'>
          <img src={guide.imageUrl} alt={guide.title} className='guide-card__image' />
        </div>
        <div className='guide-card__content'>
          <h3 className='guide-card__title'>{guide.title}</h3>
          <p className='guide-card__description'>{guide.description}</p>
          <div className='guide-card__tags'>
            {guide.tags.map((tag, index) => (
              <span key={index} className='guide-card__tag'>
                {tag}
              </span>
            ))}
          </div>
          <div className='guide-card__footer'>
            <div className='guide-card__creator'>
              {guide.creator.profilePic && (
                <img
                  src={guide.creator.profilePic.url}
                  alt={`${guide.creator.firstName}'s profile`}
                  className='guide-card__creator-image'
                />
              )}
              <span className='guide-card__creator-name'>
                {guide.creator.firstName} {guide.creator.lastName}
              </span>
            </div>
            <div className='guide-card__price'>{formattedPrice}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};
