interface BaseMediaConfig {
  maxSize: number;
  acceptedFormats: readonly string[];
  formatError: string;
}

export interface ImageConfig extends BaseMediaConfig {
  type: 'image';
}

export interface AudioConfig extends BaseMediaConfig {
  type: 'audio';
}

export interface VideoConfig extends BaseMediaConfig {
  type: 'video';
}

export type MediaConfig = ImageConfig | AudioConfig | VideoConfig;
export type MediaType = MediaConfig['type'];

export const MEDIA_CONFIG: { [K in MediaType]: Extract<MediaConfig, { type: K }> } = {
  image: {
    type: 'image',
    maxSize: 512 * 1024, // 0.5MB
    acceptedFormats: ['.jpg', '.jpeg', '.png', '.webp'],
    formatError: 'Please select a JPG, PNG or WebP image',
  },
  audio: {
    type: 'audio',
    maxSize: 10 * 1024 * 1024, // 10MB
    acceptedFormats: ['.mp3', '.wav', '.m4a'],
    formatError: 'Please select an MP3, WAV or M4A audio file',
  },
  video: {
    type: 'video',
    maxSize: 25 * 1024 * 1024, // 25MB
    acceptedFormats: ['.mp4', '.mov', '.webm'],
    formatError: 'Please select an MP4, MOV or WebM video',
  },
} as const;
