import React, { useState } from 'react';
import { MapComponent } from './MapComponent';
import { getPolygons, postPolygonAndGetGeohashes } from '../api/polygon_coverage';
import {
  getExperiences,
  getExperiencePoints,
  resetExperienceRatingsForAlex,
  resetExperienceRatingsForJane,
} from '../api/channel_api';
import { ResponsiveBox } from '../component/responsive_box/ResponsiveBox';
import { CustomButton } from '../component/button/CustomButton';
import { MapExperiencesComponent } from './MapExperienceCoverage';
import { LookAroundPage } from '../component/experiences/lookaround_page/LookAroundPage';
import { sendEmail, runTestCode } from '../api/authentication_api';
import { SignedInNavbar } from '../component/navbar/SignedInNavbar';

export const MapPage = ({ homepageCallback, signInState }) => {
  const [response, setResponse] = useState([]);
  const [submitState, setSubmitState] = useState('before');
  const [commitState, setCommitState] = useState('before');
  const [coordinatesText, setCoordinatesText] = useState('');
  const [lookaroundState, setLookaroundState] = useState('not_looking');
  const [channelResponse, setChannelResponse] = useState({ local: [], nearby: [], serviceType: '' });
  const [polygonNames, setPolygonNames] = useState('');
  const [polygons, setPolygons] = useState([]);
  const [polygonUpdateKey, setPolygonUpdateKey] = useState(0);
  const [experiencePoints, setExperiencePoints] = useState([]);
  const [experiencePointsStatus, setExperiencePointsStatus] = useState('');
  const [resetExperiencesForAlexStatus, setReserExperiencesForAlexStatus] = useState('');
  const [resetExperiencesForJaneStatus, setReserExperiencesForJaneStatus] = useState('');
  const [sendEmailStatus, setSendEmailStatus] = useState('');
  const [runTestCodeStatus, setRunTestCodeStatus] = useState('');

  const handleSubmit = async ({ commit = false }) => {
    if (!commit) {
      setSubmitState('during');
    } else {
      setCommitState('during');
    }
    await postPolygonAndGetGeohashes(polygons, commit).then(geohashResponse => {
      if (geohashResponse.statusCode === 400 || geohashResponse.statusCode === 404) {
        setResponse('Failed');
        if (!commit) {
          setSubmitState('before');
        } else {
          setCommitState('before');
        }
      } else if (geohashResponse.statusCode === 200) {
        setResponse(geohashResponse.polygon_geohashes);
        if (!commit) {
          setSubmitState('success');
        } else {
          setCommitState('success');
        }
      } else {
        if (!commit) {
          setSubmitState('error');
        } else {
          setCommitState('error');
        }
        throw new Error(`Error ${geohashResponse.statusCode}: Something unexpected happened`);
      }
    });
  };

  const getChannels = async (latitude, longitude) => {
    setLookaroundState('looking');
    getExperiences(latitude, longitude).then(channelResponse => {
      if (channelResponse.statusCode === 200) {
        setLookaroundState('not_looking');
        setChannelResponse({
          local: channelResponse.channels,
          nearby: channelResponse.channels_nearby,
          serviceType: channelResponse.service_type,
        });
      } else {
        setLookaroundState('error');
      }
    });
  };

  const handleCoordinatesSubmit = event => {
    event.preventDefault();
    setLookaroundState('looking');
    const parts = coordinatesText.split(',').map(part => part.trim());
    const latitude = parseFloat(parts[0]);
    const longitude = parseFloat(parts[1]);
    if (!isNaN(latitude) && !isNaN(longitude)) {
      getChannels(latitude, longitude);
    } else {
      console.error('Invalid input. Please enter two numbers separated by a comma.');
    }
  };

  const handlePolygonNamesSubmit = event => {
    event.preventDefault();
    if (polygonNames !== '') {
      getPolygons(polygonNames).then(polygonsResponse => {
        if (polygonsResponse.statusCode === 200) {
          setPolygons(polygonsResponse['polygons']);
          setPolygonUpdateKey(prevKey => prevKey + 1); // Update the key to force re-render
        } else {
          console.error('Something went wrong: ', polygonsResponse);
        }
      });
    } else {
      console.error('Invalid input. Enter at least one name.');
    }
  };

  const createChannelChildren = [
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomButton
            button_text='Create experience'
            onClickFunction={() => {
              homepageCallback({ callbackType: 'createExperience', payload: {} });
            }}
            type='primary'
          />
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <input
            type='text'
            placeholder='latitude, longitude'
            value={coordinatesText}
            onChange={e => setCoordinatesText(e.target.value)}
          />
          {lookaroundState === 'not_looking' && (
            <CustomButton button_text='View experiences' onClickFunction={handleCoordinatesSubmit} />
          )}
          {lookaroundState === 'looking' && <div>...</div>}
          {lookaroundState === 'error' && <div>error</div>}
        </div>
      ),
    },
    {
      leftChild:
        channelResponse.serviceType !== '' ? (
          <div style={{ marginTop: '20px' }}>
            <LookAroundPage
              homepageCallback={homepageCallback}
              lookAroundChannelList={channelResponse}
              verifyToken={() => {}}
              onSignInFailure={() => {}}
              signInStatus='signedIn'
              userExperienceCount={signInState.experienceCount}
              username={signInState.username}
              totalExperienceCount={signInState.totalExperienceCount}
            />
          </div>
        ) : (
          <></>
        ),
    },
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {experiencePoints.length > 0 && (
            <div style={{ width: '100%', marginTop: '40px' }}>
              {`${experiencePoints.length} experiences found`}
              <MapExperiencesComponent key='something' initialPoints={experiencePoints} initialPolygons={[]} />
            </div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          {experiencePointsStatus === '' ? (
            <CustomButton
              button_text='See experience coverage'
              onClickFunction={() => {
                setExperiencePointsStatus('fetching');
                getExperiencePoints().then(experiencePointsResponse => {
                  if (experiencePointsResponse.statusCode === 200) {
                    setExperiencePointsStatus('');
                    setExperiencePoints(experiencePointsResponse.points);
                  } else {
                    setExperiencePointsStatus('error');
                  }
                });
              }}
              type='primary'
            />
          ) : experiencePointsStatus === 'fetching' ? (
            <div>Fetching...</div>
          ) : (
            <div style={{ color: 'red' }}>Something went wrong</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          {resetExperiencesForAlexStatus === '' ? (
            <CustomButton
              button_text='Reset experiences for Alex ⚠️⚠️ Irreversible'
              onClickFunction={() => {
                setReserExperiencesForAlexStatus('resetting');
                resetExperienceRatingsForAlex().then(resetStatusResponse => {
                  if (resetStatusResponse.statusCode === 200) {
                    setReserExperiencesForAlexStatus('done');
                  } else {
                    setReserExperiencesForAlexStatus('error');
                  }
                });
              }}
              type='accent'
            />
          ) : resetExperiencesForAlexStatus === 'resetting' ? (
            <div>Resetting...</div>
          ) : resetExperiencesForAlexStatus === 'done' ? (
            <div>Experiences for Alex successfully reset</div>
          ) : (
            <div style={{ color: 'red' }}>Something went wrong</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          {resetExperiencesForJaneStatus === '' ? (
            <CustomButton
              button_text='Reset experiences for Jane ⚠️⚠️ Irreversible'
              onClickFunction={() => {
                setReserExperiencesForJaneStatus('resetting');
                resetExperienceRatingsForJane().then(resetStatusResponse => {
                  if (resetStatusResponse.statusCode === 200) {
                    setReserExperiencesForJaneStatus('done');
                  } else {
                    setReserExperiencesForJaneStatus('error');
                  }
                });
              }}
              type='accent'
            />
          ) : resetExperiencesForJaneStatus === 'resetting' ? (
            <div>Resetting...</div>
          ) : resetExperiencesForJaneStatus === 'done' ? (
            <div>Experiences for Jane successfully reset</div>
          ) : (
            <div style={{ color: 'red' }}>Something went wrong</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          {sendEmailStatus === '' ? (
            <CustomButton
              button_text='Send email'
              onClickFunction={() => {
                setSendEmailStatus('sending');
                sendEmail().then(sendEmailResponse => {
                  if (sendEmailResponse.statusCode === 200) {
                    setSendEmailStatus('sent');
                  } else {
                    setSendEmailStatus('error');
                  }
                });
              }}
              type='accent'
            />
          ) : sendEmailStatus === 'sending' ? (
            <div>Sending...</div>
          ) : sendEmailStatus === 'sent' ? (
            <div>Email sent</div>
          ) : (
            <div style={{ color: 'red' }}>Something went wrong</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          {runTestCodeStatus === '' ? (
            <CustomButton
              button_text='Run test code'
              onClickFunction={() => {
                setRunTestCodeStatus('running');
                runTestCode().then(testCodeResponse => {
                  if (testCodeResponse.statusCode === 200) {
                    setRunTestCodeStatus('success');
                  } else {
                    setRunTestCodeStatus('error');
                  }
                });
              }}
              type='accent'
            />
          ) : runTestCodeStatus === 'running' ? (
            <div>Running...</div>
          ) : runTestCodeStatus === 'success' ? (
            <div>Success!</div>
          ) : (
            <div style={{ color: 'red' }}>Something went wrong</div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: '100vh' }}>
      <SignedInNavbar homepageCallback={homepageCallback} />
      <ResponsiveBox isSnug={true} children={createChannelChildren} />
      <h1>Draw a Polygon on the Map</h1>
      <h2>Initial polygons</h2>
      <form onSubmit={handlePolygonNamesSubmit}>
        <label>
          Names
          <input type='text' value={polygonNames} onChange={e => setPolygonNames(e.target.value)} />
        </label>
        <button type='submit'>Submit</button>
      </form>
      <MapComponent key={polygonUpdateKey} initialPolygons={polygons} setPolygons={setPolygons} />
      <div>
        <h2>Polygons</h2>
        <pre>{JSON.stringify(polygons, (k, v) => v, 2)}</pre>
        {submitState === 'success' && 'Submitted successfully!'}
        {submitState === 'error' && 'Something went wrong while submitting'}
        {submitState === 'during' && 'Submitting...'}
        {submitState === 'before' && <button onClick={handleSubmit}>Submit Polygons</button>}
      </div>
      {response.length > 0 && (
        <div>
          <h3>Response</h3>
          <MapComponent
            // Plot both initial polygon and fitted convex hull. Change IDs so leaflet does not see duplicates.
            initialPolygons={[
              ...response.map(e => {
                return { ...e, id: 'fitted_' + e.id };
              }),
              ...polygons,
            ]}
            setPolygons={() => {}}
            editable={false}
          />
          {commitState === 'success' && 'Committed successfully!'}
          {commitState === 'error' && 'Something went wrong while committing'}
          {commitState === 'during' && 'Committing...'}
          {commitState === 'before' && (
            <div>
              <pre>
                {JSON.stringify(
                  response,
                  (k, v) => {
                    if (k === 'coordinates') {
                      // Don't print coordinates again.
                      return undefined;
                    } else if (k === 'geohashes') {
                      return v.length;
                    } else {
                      return v;
                    }
                  },
                  2
                )}
              </pre>
              <button
                onClick={() => {
                  handleSubmit({ commit: true });
                }}
              >
                Commit Polygons
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
