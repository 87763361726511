import React from 'react';
import './LoadingScreen.css';

export const LoadingScreen = () => {
  return (
    <div className='loading-screen'>
      <div className='loading-screen__content'>
        <div className='loading-screen__spinner'></div>
        <div className='loading-screen__brand'>LookAround</div>
      </div>
    </div>
  );
};
