import React from 'react';
import { ChevronDown } from 'lucide-react';
import type { StopSection } from '../../../../types/guide';
import { EditableText } from '../shared/EditableText';
import './StopSectionEditor.css';
import { MediaEditor } from '../shared/MediaEditor';

interface StopSectionEditorProps {
  section: StopSection;
  isActive: boolean;
  onActivate: () => void;
  onUpdate: (updates: Partial<StopSection>) => void;
}

export const StopSectionEditor: React.FC<StopSectionEditorProps> = ({ section, isActive, onActivate, onUpdate }) => {
  return (
    <div className={`stop-section-editor ${isActive ? 'stop-section-editor--active' : ''}`}>
      <div className='stop-section-editor__header' onClick={onActivate}>
        <h5 className='stop-section-editor__title'>{section.title || 'Untitled Section'}</h5>
        <ChevronDown
          size={20}
          className={`stop-section-editor__chevron ${isActive ? 'stop-section-editor__chevron--expanded' : ''}`}
        />
      </div>

      {isActive && (
        <div className='stop-section-editor__content'>
          <EditableText
            value={section.title || ''}
            onChange={value => onUpdate({ title: value })}
            placeholder='Section title (optional)'
          />

          <EditableText
            value={section.paragraph || ''}
            onChange={value => onUpdate({ paragraph: value })}
            multiline
            placeholder='Section content'
          />

          <MediaEditor type='image' current={section.captionedImage} />

          <MediaEditor type='audio' current={section.audio} />

          <MediaEditor type='video' current={section.video} />
        </div>
      )}
    </div>
  );
};
