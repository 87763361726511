import { useEffect, useState, useCallback } from 'react';

const APPLE_SIGN_IN_CONFIG = {
  clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
  scope: 'email name',
  redirectURI:
    window.location.hostname === 'www.lookaround.live'
      ? 'https://www.lookaround.live/api/auth/sign_in_with_apple'
      : 'https://lookaround-live.herokuapp.com/api/auth/sign_in_with_apple',
  state: 'lookaround-creators',
  usePopup: true,
};

export const useAppleAuth = () => {
  const [isAppleAuthReady, setIsAppleAuthReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAppleAuthReady = setInterval(() => {
      if (window.AppleID) {
        clearInterval(checkAppleAuthReady);
        setIsAppleAuthReady(true);
      }
    }, 100);

    return () => clearInterval(checkAppleAuthReady);
  }, []);

  useEffect(() => {
    if (isAppleAuthReady) {
      try {
        window.AppleID.auth.init(APPLE_SIGN_IN_CONFIG);
      } catch (error) {
        console.error('Error initializing Apple Sign In:', error);
        setError(error);
      }
    }
  }, [isAppleAuthReady]);

  const handleAppleSignIn = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const auth = await window.AppleID.auth.signIn();
      console.log('Apple auth response:', auth); // For debugging

      if (auth?.authorization) {
        // Prepare the request payload
        const payload = {
          identity_token: auth.authorization.id_token,
          code: auth.authorization.code,
          state: auth.authorization.state,
          platform: 'web',
        };

        // Only add user data if it exists (first time sign in)
        if (auth.user) {
          payload.given_name = auth.user.name?.firstName || null;
          payload.family_name = auth.user.name?.lastName || null;
          payload.email = auth.user.email || null;
        }

        const response = await fetch(APPLE_SIGN_IN_CONFIG.redirectURI, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Important for cookie handling
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to authenticate with backend');
        }

        const data = await response.json();
        return data;
      }
    } catch (error) {
      if (error.error === 'popup_closed_by_user') {
        console.log('Sign in popup was closed by the user');
        return;
      }
      console.error('Apple Sign In error:', error); // For debugging
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isAppleAuthReady,
    isLoading,
    error,
    handleAppleSignIn,
  };
};
