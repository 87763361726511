import React from 'react';
import { Navigation } from '../../components/layout/Navigation/Navigation';
import { Hero } from '../../components/sections/Hero/Hero';
import { Features } from '../../components/sections/Features/Features';
import { Footer } from '../../components/layout/Footer/Footer';
import { Testimonials } from '../../components/sections/Testimonials/Testimonials';
import { CTA } from '../../components/sections/CTA/CTA';
import '../../styles/globals.css';

export const LandingPage = () => (
  <>
    <meta name='apple-itunes-app' content='app-id=6670697196' />
    <Navigation />
    <main>
      <Hero />
      <Features />
      <Testimonials />
      <CTA />
    </main>
    <Footer />
  </>
);
