import React from 'react';
import { APP_STORE_URL } from '../../../constants/theme';
import './CTA.css';

export const CTA = () => (
  <section className='cta'>
    <div className='container'>
      <h2 className='cta-title'>Start Exploring Today</h2>
      <p className='cta-text'>
        Download LookAround and start discovering San Francisco's hidden treasures.
        <br />
        <a href='/creators' className='cta-link'>
          Become a creator
        </a>{' '}
        and work with us to start monetizing your content.
      </p>
      <a
        href={APP_STORE_URL}
        target='_blank'
        rel='noopener noreferrer'
        style={{
          display: 'inline-block',
          width: '200px',
          transition: 'transform 0.2s ease, opacity 0.2s ease',
        }}
      >
        <img
          src='https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/white/en-us'
          alt='Download on the App Store'
          style={{ width: '100%', height: 'auto' }}
        />
      </a>
    </div>
  </section>
);
