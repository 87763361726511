import React from 'react';
import './Button.css';

export const Button = ({ children, variant = 'primary', className = '', href, ...props }) => {
  const buttonClass = `button button-${variant} ${className}`;

  if (href) {
    return (
      <a href={href} className={buttonClass} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button className={buttonClass} {...props}>
      {children}
    </button>
  );
};
