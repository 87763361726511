import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { EditableText } from '../shared/EditableText';
import type { GuideStop, StopDetails } from '../../../../types/guide';
import L, { LatLngTuple } from 'leaflet';
import './StopContentDetails.css';
import { AudioPlayer } from '../../../common/media/AudioPlayer/AudioPlayer';

// Define component props interface
interface StopContentDetailsProps {
  stop: GuideStop;
  details?: StopDetails;
  isEditing: boolean;
  onUpdate: (changes: Partial<StopDetails>) => void;
}

const customIcon = new L.Icon({
  iconUrl:
    'data:image/svg+xml;base64,' +
    btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#026440" width="32" height="32">
      <path d="M12 0c-4.4 0-8 3.6-8 8c0 5.4 7 13.4 7.3 13.7c0.4 0.4 1 0.4 1.4 0c0.3-0.3 7.3-8.3 7.3-13.7c0-4.4-3.6-8-8-8zm0 12c-2.2 0-4-1.8-4-4s1.8-4 4-4s4 1.8 4 4s-1.8 4-4 4z"/>
    </svg>
  `),
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const DEFAULT_POSITION: LatLngTuple = [37.7749, -122.4194]; // Default to SF

const StopContentDetails: React.FC<StopContentDetailsProps> = ({ stop, details, isEditing, onUpdate }) => {
  const hasLocation = details?.navigationLatitude && details?.navigationLongitude;
  // Use type assertion to ensure position is always a valid LatLngTuple
  const position: LatLngTuple = hasLocation
    ? ([details.navigationLatitude, details.navigationLongitude] as LatLngTuple)
    : DEFAULT_POSITION;

  // Early return if no details
  if (!details) return null;

  if (!isEditing) {
    return (
      <div className='stop-content-details'>
        {details.imageUrl && (
          <div className='stop-content-details__image-container'>
            <img src={details.imageUrl} alt={details.title || stop.title} className='stop-content-details__image' />
          </div>
        )}

        {details.title && (
          <div className='stop-content-details__title-container'>
            <h3 className='stop-content-details__title'>{details.title}</h3>
          </div>
        )}

        {/* Description */}
        {details.description && <p className='stop-content-details__description'>{details.description}</p>}

        {details.tags && details.tags.length > 0 && (
          <div className='stop-content-details__tags'>
            {details.tags.map((tag, index) => (
              <span key={index} className='stop-content-details__tag'>
                {tag}
              </span>
            ))}
          </div>
        )}

        {hasLocation && (
          <div className='stop-content-details__map-container'>
            <MapContainer center={position} zoom={15} className='stop-content-details__map' scrollWheelZoom={false}>
              <TileLayer
                url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              />
              <Marker position={position} icon={customIcon as L.Icon}>
                <Popup>
                  <div className='stop-content-details__location-popup'>
                    <p>Latitude: {details.navigationLatitude}</p>
                    <p>Longitude: {details.navigationLongitude}</p>
                  </div>
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        )}
        {details.sections.map((section, idx) => (
          <div key={idx} className='stop-content-details__section'>
            {section.title && <h4 className='stop-content-details__section-title'>{section.title}</h4>}
            {section.audio && (
              <AudioPlayer
                id={`section-audio-${stop.id}-${idx}`}
                title={section.audio.title}
                audioUrl={section.audio.audioFileUrl}
                className='audio-player--nested'
                isEditing={false}
              />
            )}
            {section.paragraph && <p className='stop-content-details__section-text'>{section.paragraph}</p>}
            {section.captionedImage && (
              <div className='stop-content-details__section-image-container'>
                <img
                  src={section.captionedImage.imageUrl}
                  alt={section.captionedImage.caption || section.title}
                  className='stop-content-details__section-image'
                />
                {section.captionedImage.caption && (
                  <p className='stop-content-details__section-image-caption'>{section.captionedImage.caption}</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className='stop-content-details'>
      {/* Image Section */}
      {(isEditing || details?.imageUrl) && (
        <div className='stop-content-details__image-container'>
          {isEditing ? (
            <EditableText
              label='Image URL'
              value={details?.imageUrl || ''}
              onChange={(value: string) => onUpdate({ imageUrl: value })}
              placeholder='Enter image URL'
            />
          ) : (
            details?.imageUrl && (
              <img src={details.imageUrl} alt={details.title || stop.title} className='stop-content-details__image' />
            )
          )}
        </div>
      )}

      {/* Title Section */}
      {(isEditing || details?.title) && (
        <div className='stop-content-details__title-container'>
          {isEditing ? (
            <EditableText
              label='Title'
              value={details?.title || ''}
              onChange={(value: string) => onUpdate({ title: value })}
              placeholder='Enter title'
            />
          ) : (
            details?.title && <h4 className='stop-content-details__title'>{details.title}</h4>
          )}
        </div>
      )}

      {/* Tags Section */}
      {(isEditing || (details?.tags && details.tags.length > 0)) && (
        <div className='stop-content-details__tags'>
          {isEditing ? (
            <EditableText
              label='Tags (comma-separated)'
              value={details?.tags?.join(', ') || ''}
              onChange={(value: string) =>
                onUpdate({
                  tags: value
                    .split(',')
                    .map(tag => tag.trim())
                    .filter(Boolean),
                })
              }
              placeholder='Enter tags, separated by commas'
            />
          ) : (
            details?.tags && (
              <div className='stop-content-details__tags-list'>
                {details.tags.map((tag, index) => (
                  <span key={index} className='stop-content-details__tag'>
                    {tag}
                  </span>
                ))}
              </div>
            )
          )}
        </div>
      )}

      {/* Location Section */}
      {(isEditing || hasLocation) && (
        <div className='stop-content-details__location'>
          {isEditing ? (
            <div className='stop-content-details__location-inputs'>
              <EditableText
                label='Latitude'
                value={details?.navigationLatitude?.toString() || ''}
                onChange={(value: string) => {
                  const num = parseFloat(value);
                  if (!isNaN(num)) {
                    onUpdate({ navigationLatitude: num });
                  }
                }}
                placeholder='Enter latitude'
              />
              <EditableText
                label='Longitude'
                value={details?.navigationLongitude?.toString() || ''}
                onChange={(value: string) => {
                  const num = parseFloat(value);
                  if (!isNaN(num)) {
                    onUpdate({ navigationLongitude: num });
                  }
                }}
                placeholder='Enter longitude'
              />
            </div>
          ) : (
            hasLocation && (
              <div className='stop-content-details__map-container'>
                <MapContainer center={position} zoom={13} className='stop-content-details__map'>
                  <TileLayer
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={position} icon={customIcon}>
                    <Popup>
                      <div className='stop-content-details__location-popup'>
                        <p>Latitude: {details.navigationLatitude}</p>
                        <p>Longitude: {details.navigationLongitude}</p>
                      </div>
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default StopContentDetails;
