import React from 'react';
import './Testimonials.css';

const TESTIMONIALS = [
  {
    text: 'LookAround helped me discover amazing places in SF that I never knew existed, even after living here for years!',
    author: 'Evan G.',
    role: 'Telegraph Hill Resident',
  },
  {
    text: 'I tried LookAround for its content and ended up re-discovering my passion for storytelling. The team is very proactive and is building something truly meaningful.',
    author: 'Sid G.',
    role: 'Content creator',
  },
  {
    text: 'It was amazing to learn the origins of Nob Hill and the stories of the businesses. It felt like being part of the movie of SF. Thank you!',
    author: 'Lindsay W.',
    role: 'Tourist',
  },
];

export const Testimonials = () => (
  <section className='testimonials'>
    <div className='container'>
      <h2 className='testimonials-title'>What Our Users Say</h2>
      <div className='testimonials-grid'>
        {TESTIMONIALS.map((testimonial, index) => (
          <div key={index} className='testimonial-card'>
            <p className='testimonial-text'>{testimonial.text}</p>
            <div>
              <p className='testimonial-author'>{testimonial.author}</p>
              <p className='testimonial-role'>{testimonial.role}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);
