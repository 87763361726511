import { asyncPostRequest } from '../../../api/requests';
import { FeedbackFormData, FeedbackApiResponse } from '../../domain/feedback/FeedbackForm';

export const submitFeedback = async (formData: FeedbackFormData): Promise<FeedbackApiResponse> => {
  try {
    await asyncPostRequest('/api/user_feedback/rate_experience', formData);

    return { success: true };
  } catch (error) {
    return {
      success: false,
      error: error instanceof Error ? error.message : 'An unknown error occurred',
    };
  }
};
