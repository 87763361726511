import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'lucide-react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { GuideOverviewEdit } from './editor/overview/GuideOverviewEdit';
import { GuideStopEdit } from './editor/stops/GuideStopEdit';
import { LoadingScreen } from '../common/LoadingScreen/LoadingScreen';
import { ErrorScreen } from '../common/ErrorScreen/ErrorScreen';
import { guideDetailsForCreator } from '../../api/creators/guide_operations';
import { useGuideEditor } from '../../hooks/useGuideEditor';
import type { GuideDetails, GuideStop } from '../../types/guide';
import './GuideManager.css';
import { AudioResource, CaptionedImage, VideoResource } from '@/v2/types/media';

interface GuideManagerProps {
  guideId: string;
  onSave: (guide: GuideDetails) => Promise<void>;
  onNavigateBack?: () => void;
}

export const GuideManager: React.FC<GuideManagerProps> = ({ guideId, onSave, onNavigateBack }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [guideDetails, setGuideDetails] = useState<GuideDetails | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);

  // Initialize with empty guide details that will be updated
  const initialGuideDetails: GuideDetails = {
    overview: {
      id: '',
      title: '',
      description: '',
      stops: [],
      url: '',
    },
    stop_details: [],
  };

  // Initialize hook with initial state - will be updated when data is fetched
  const { guide, editState, startEditing, cancelEditing, updateOverview, updateStop, saveChanges, setGuide } =
    useGuideEditor(guideDetails || initialGuideDetails);

  useEffect(() => {
    const fetchGuideDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await guideDetailsForCreator({ overview_id: guideId });

        if (response.isOk) {
          setGuideDetails(response);
          setGuide(response); // Update the editor state directly
        } else {
          throw new Error('Failed to fetch guide details');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load guide details');
      } finally {
        setLoading(false);
      }
    };

    fetchGuideDetails();
  }, [guideId, setGuide]);

  // Don't initialize the editor until we have guide details
  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !guideDetails) {
    return <ErrorScreen message={error || 'Unable to load guide'} />;
  }

  const handleSave = async () => {
    setIsSaving(true);
    setSaveError(null);
    try {
      const updatedGuide = saveChanges();
      await onSave(updatedGuide);
    } catch (error) {
      setSaveError('Failed to save guide. Please try again.');
      console.error('Failed to save guide:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    if (editState.isEditing) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        cancelEditing();
        onNavigateBack?.();
      }
    } else {
      onNavigateBack?.();
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !editState.isEditing) return;

    const stops = Array.from(guide.overview.stops);
    const [reorderedStop] = stops.splice(result.source.index, 1);
    stops.splice(result.destination.index, 0, reorderedStop);

    // Update the stop order
    updateOverview({ stops });
  };

  const addNewStop = () => {
    const newStop: GuideStop = {
      id: `new-${Date.now()}`,
      childId: `child-${Date.now()}`,
      title: 'New Stop',
      description: '',
    };

    updateOverview({
      stops: [...guide.overview.stops, newStop],
    });
  };

  const deleteStop = (stopId: string) => {
    updateOverview({
      stops: guide.overview.stops.filter(stop => stop.id !== stopId),
    });
  };

  const handleUpdateHeaderImage = async (image: CaptionedImage | null) => {
    console.log(`Header image updated to ${image}`);
    updateOverview({ headerImage: image });
  };

  const handleUpdateAudio = async (audio: AudioResource | null) => {
    updateOverview({ audio });
  };

  const handleUpdateVideo = async (video: VideoResource | null) => {
    updateOverview({ video });
  };

  return (
    <div className='guide-manager'>
      <div className='guide-manager__header'>
        {onNavigateBack && (
          <button className='guide-manager__back-button' onClick={handleBack}>
            <ArrowLeft size={20} />
            Back to Dashboard
          </button>
        )}
        <div className='guide-manager__actions'>
          {editState.isEditing ? (
            <>
              <button className='button button-light' onClick={cancelEditing} disabled={isSaving}>
                Cancel
              </button>
              <button className='button button-primary' onClick={handleSave} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save Changes'}
              </button>
            </>
          ) : (
            <button className='button button-primary' onClick={startEditing}>
              Edit Guide
            </button>
          )}
        </div>
      </div>

      {saveError && <div className='guide-manager__error'>{saveError}</div>}

      <div className='guide-manager__content'>
        <GuideOverviewEdit
          guide={guide}
          isEditing={editState.isEditing}
          onUpdateTitle={title => updateOverview({ title })}
          onUpdateDescription={description => updateOverview({ description })}
          onUpdateHeaderImage={handleUpdateHeaderImage}
          onUpdateAudio={handleUpdateAudio}
          onUpdateVideo={handleUpdateVideo}
          onAddStop={addNewStop}
          onSave={handleSave}
          onCancel={cancelEditing}
        />

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='stops'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps} className='guide-manager__stops'>
                {guide.overview.stops.map((stop, index) => {
                  // Find matching stop details
                  const details = guide.stop_details[index];

                  return (
                    <GuideStopEdit
                      key={stop.id}
                      stop={stop}
                      details={details}
                      index={index}
                      isEditing={editState.isEditing}
                      onUpdate={updateStop}
                      onDelete={deleteStop}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
