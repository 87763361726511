import React from 'react';
import { LocalGuideTile } from '../../../domain/guides/PublicGuides';
import './GuideList.css';

interface GuideListProps {
  guides: LocalGuideTile[];
  onGuideSelect: (guideId: string) => void;
  isLoading?: boolean;
  error?: string | null;
}

export const GuideList: React.FC<GuideListProps> = ({ guides, onGuideSelect, isLoading, error }) => {
  if (isLoading) {
    return <div className='guide-list__loading'>Loading guides...</div>;
  }

  if (error) {
    return <div className='guide-list__error'>{error}</div>;
  }

  if (guides.length === 0) {
    return <div className='guide-list__empty'>No guides found</div>;
  }

  return (
    <div className='guide-list__grid'>
      {guides.map(guide => (
        <div key={guide.id} className='guide-list__tile' onClick={() => onGuideSelect(guide.childId)}>
          <img src={guide.imageUrl} alt={guide.title} className='guide-list__image' />
          <div className='guide-list__content'>
            <h3 className='guide-list__title'>{guide.title}</h3>
            <p className='guide-list__description'>{guide.description}</p>
            <div className='guide-list__tags'>
              {guide.tags.map((tag, index) => (
                <span key={index} className='guide-list__tag'>
                  {tag}
                </span>
              ))}
            </div>
            <div className='guide-list__footer'>
              <div className='guide-list__creator'>
                {guide.creator.firstName} {guide.creator.lastName}
              </div>
              <div className='guide-list__price'>${(guide.priceCents / 100).toFixed(2)}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
