// hooks/useMediaUpload.ts
import { useState, useCallback, useRef } from 'react';
import { s3SignedUrl, putFile } from '../../api/s3';
import type { CaptionedImage, AudioResource, VideoResource } from '../types/media';
import { MEDIA_CONFIG, MediaType } from '../components/guides/editor/shared/media/MediaConfig';

type MediaTypeMap = {
  image: CaptionedImage;
  audio: AudioResource;
  video: VideoResource;
};

interface UseMediaUploadConfig<T extends MediaType> {
  type: T;
  onUpload: (media: MediaTypeMap[T] | null) => void;
}

export function useMediaUpload<T extends MediaType>({ type, onUpload }: UseMediaUploadConfig<T>) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const config = MEDIA_CONFIG[type];

  // Helper to convert to display size
  const formatSize = (bytes: number) => {
    const mb = bytes / (1024 * 1024);
    return `${mb < 1 ? mb.toFixed(2) : mb.toFixed(1)}MB`;
  };

  const validateFile = useCallback(
    (file: File): string | null => {
      // Check file size
      if (file.size > config.maxSize) {
        return `File size must be less than ${formatSize(config.maxSize)}`;
      }

      // Check file format
      const extension = `.${file.name.split('.').pop()?.toLowerCase()}`;
      if (!config.acceptedFormats.includes(extension)) {
        return config.formatError;
      }

      return null;
    },
    [config]
  );

  const handleUpload = useCallback(
    async (file: File) => {
      const validationError = validateFile(file);
      console.log(`Validation error: ${validationError}`);
      if (validationError) {
        setError(validationError);
        return;
      }

      try {
        setError(null);
        setIsUploading(true);

        // Get signed URL
        const fileName = `${type}_${Date.now()}_${file.name}`;
        const response = await s3SignedUrl(fileName, file.type);

        console.log(`${fileName}, ${response}`);

        if (!response.isOk || !response.signedUrl || !response.file_name) {
          throw new Error('Failed to get upload URL');
        }

        // Upload file
        await putFile(response.signedUrl, file.type, file);

        // Create appropriate media resource based on type
        let mediaResource: MediaTypeMap[T];
        let resourceUrl = `https://lookaround-live.s3.amazonaws.com/${response.file_name}`;
        switch (type) {
          case 'image':
            mediaResource = {
              imageUrl: resourceUrl,
              caption: file.name,
            } as MediaTypeMap[T];
            break;
          case 'audio':
            mediaResource = {
              audioFileUrl: resourceUrl,
              title: file.name,
            } as MediaTypeMap[T];
            break;
          case 'video':
            mediaResource = {
              videoFileUrl: resourceUrl,
              title: file.name,
              thumbnailImageUrl: 'https://lookaround-live.s3.us-west-1.amazonaws.com/static/playstore.png',
            } as MediaTypeMap[T];
            break;
          default:
            throw new Error(`Unsupported media type ${type}`);
        }

        onUpload(mediaResource);
      } catch (err) {
        setError('Upload failed. Please try again.');
        console.error('Upload error:', err);
      } finally {
        setIsUploading(false);
      }
    },
    [type, validateFile, onUpload]
  );

  const openFileSelector = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      console.log(file);
      if (file) {
        handleUpload(file);
      }
    },
    [handleUpload]
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(false);

      const file = event.dataTransfer.files[0];
      if (file) {
        handleUpload(file);
      }
    },
    [handleUpload]
  );

  return {
    fileInputRef,
    isUploading,
    isDragging,
    error,
    setIsDragging,
    openFileSelector,
    handleDrop,
    handleChange,
    acceptedFormats: config.acceptedFormats.join(','),
  };
}
