import React from 'react';
import type { CaptionedImage, AudioResource, VideoResource } from '../../../../types/media';
import './MediaEditor.css';

type MediaTypes = {
  image: CaptionedImage;
  audio: AudioResource;
  video: VideoResource;
};

interface MediaEditorProps<T extends keyof MediaTypes> {
  type: T;
  current?: MediaTypes[T];
}

export const MediaEditor = <T extends keyof MediaTypes>({ type, current }: MediaEditorProps<T>) => {
  const renderPreview = () => {
    if (!current) return null;

    switch (type) {
      case 'image':
        return (
          <img
            src={(current as CaptionedImage).imageUrl}
            alt={(current as CaptionedImage).caption || ''}
            className='media-editor__preview-image'
          />
        );
      case 'audio':
        return (
          <div className='media-editor__preview-audio'>
            <audio controls src={(current as AudioResource).audioFileUrl} className='media-editor__audio-player' />
            {(current as AudioResource).title && (
              <p className='media-editor__audio-title'>{(current as AudioResource).title}</p>
            )}
          </div>
        );
      case 'video':
        return (
          <div className='media-editor__preview-video'>
            <video
              controls
              src={(current as VideoResource).videoFileUrl}
              poster={(current as VideoResource).thumbnailImageUrl}
              className='media-editor__video-player'
            />
            {(current as VideoResource).title && (
              <p className='media-editor__video-title'>{(current as VideoResource).title}</p>
            )}
          </div>
        );
    }
  };

  return <div className='media-editor'>{renderPreview()}</div>;
};
