import { useState, useCallback } from 'react';
import { GuideDetails, EditState, GuideOverview, GuideStop, StopDetails } from '../types/guide';

interface UseGuideEditorResult {
  guide: GuideDetails;
  editState: EditState;
  startEditing: () => void;
  cancelEditing: () => void;
  updateOverview: (changes: Partial<GuideOverview>) => void;
  updateStop: (stopId: string, changes: Partial<GuideStop>) => void;
  updateStopDetails: (stopId: string, changes: Partial<StopDetails>) => void;
  saveChanges: () => GuideDetails;
  setGuide: (guide: GuideDetails) => void;
}

export const useGuideEditor = (initialGuide: GuideDetails): UseGuideEditorResult => {
  const [guide, setGuide] = useState<GuideDetails>(initialGuide);
  const [editState, setEditState] = useState<EditState>({
    isEditing: false,
    pendingChanges: {
      stops: {},
      stop_details: {},
    },
  });

  const startEditing = useCallback(() => {
    setEditState(prev => ({ ...prev, isEditing: true }));
  }, []);

  const cancelEditing = useCallback(() => {
    setEditState({
      isEditing: false,
      pendingChanges: {
        stops: {},
        stop_details: {},
      },
    });
  }, []);

  const updateOverview = useCallback((changes: Partial<GuideOverview>) => {
    console.log(changes);
    setEditState(prev => ({
      ...prev,
      pendingChanges: {
        ...prev.pendingChanges,
        overview: {
          ...prev.pendingChanges.overview,
          ...changes,
        },
      },
    }));
  }, []);

  const updateStop = useCallback((stopId: string, changes: Partial<GuideStop>) => {
    setEditState(prev => ({
      ...prev,
      pendingChanges: {
        ...prev.pendingChanges,
        stops: {
          ...prev.pendingChanges.stops,
          [stopId]: {
            ...prev.pendingChanges.stops[stopId],
            ...changes,
          },
        },
      },
    }));
  }, []);

  const updateStopDetails = useCallback((stopId: string, changes: Partial<StopDetails>) => {
    setEditState(prev => ({
      ...prev,
      pendingChanges: {
        ...prev.pendingChanges,
        stop_details: {
          ...prev.pendingChanges.stop_details,
          [stopId]: {
            ...prev.pendingChanges.stop_details[stopId],
            ...changes,
          },
        },
      },
    }));
  }, []);

  const saveChanges = useCallback((): GuideDetails => {
    const updatedGuide: GuideDetails = {
      ...guide,
      overview: {
        ...guide.overview,
        ...(editState.pendingChanges.overview || {}),
        stops: guide.overview.stops.map(stop => ({
          ...stop,
          ...(editState.pendingChanges.stops[stop.id] || {}),
        })),
      },
      stop_details: guide.stop_details.map(details => {
        const stopId = guide.overview.stops.find(stop => stop.title === details.title)?.id;

        return stopId
          ? {
              ...details,
              ...(editState.pendingChanges.stop_details[stopId] || {}),
            }
          : details;
      }),
    };

    setGuide(updatedGuide);
    cancelEditing();
    return updatedGuide;
  }, [guide, editState, cancelEditing]);

  return {
    guide,
    editState,
    startEditing,
    cancelEditing,
    updateOverview,
    updateStop,
    updateStopDetails,
    saveChanges,
    setGuide,
  };
};
