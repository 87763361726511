import React, { useState } from 'react';
import { registerInterest } from '../../../api/creators/register';
import './CreatorForm.css';

export const CreatorForm = ({ showSuccess, setShowSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    expertise: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await registerInterest(formData);
      setShowSuccess(true);
      setFormData({ name: '', email: '', expertise: '' });
      setTimeout(() => setShowSuccess(false), 5000);
    } catch (error) {
      // Handle error if needed
      console.error('Registration failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = e => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <section className='form-section'>
      <div className='container'>
        <h2 className='form-title'>Ready to Get Started?</h2>
        {showSuccess && (
          <div className='success-message'>
            Thank you for your interest! We'll review your submission and reach out soon.
          </div>
        )}
        <form onSubmit={handleSubmit} className='signup-form'>
          <div className='form-group'>
            <label htmlFor='name'>Full Name</label>
            <input
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
              disabled={isSubmitting}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email Address</label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              required
              disabled={isSubmitting}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='expertise'>What type of content would you like to create?</label>
            <textarea
              id='expertise'
              name='expertise'
              value={formData.expertise}
              onChange={handleChange}
              rows={3}
              required
              placeholder="Tell us about the content you'd like to create..."
              disabled={isSubmitting}
            />
          </div>
          <button type='submit' className={`submit-button ${isSubmitting ? 'submitting' : ''}`} disabled={isSubmitting}>
            <span className='button-text'>{isSubmitting ? 'Registering...' : 'Register as creator'}</span>
            {isSubmitting && <span className='spinner'></span>}
          </button>
        </form>
      </div>
    </section>
  );
};
