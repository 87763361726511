import React from 'react';
import './EditableText.css';

interface EditableTextProps {
  value: string;
  onChange: (value: string) => void;
  multiline?: boolean;
  label?: string;
  placeholder?: string;
}

export const EditableText: React.FC<EditableTextProps> = ({
  value,
  onChange,
  multiline = false,
  label,
  placeholder,
}) => {
  const Component = multiline ? 'textarea' : 'input';

  return (
    <div className='editable-text'>
      {label && <label className='editable-text__label'>{label}</label>}
      <Component
        className={`editable-text__input ${multiline ? 'editable-text__input--multiline' : ''}`}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        rows={multiline ? 4 : undefined}
      />
    </div>
  );
};
