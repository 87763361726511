import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from 'react-router-dom';
import { signInWithGoogle } from '../../../../../api/authentication_api';
import { Navigation } from '../../../../components/layout/Navigation/Navigation';
import { Footer } from '../../../../components/layout/Footer/Footer';
import { AppleSignInButton } from '../../../../components/auth/AppleSignInButton/AppleSignInButton';
import { useAppleAuth } from '../../../../components/auth/AppleAuthSetup';
import { useAuth } from '../../../../context/AuthContext';
import './CreatorsPortalLandingPage.css';

export const CreatorsPortalLandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isAuthenticated, isLoading } = useAuth();
  const [googleLoading, setGoogleLoading] = useState(false);
  const { isAppleAuthReady, isLoading: appleLoading, error: appleError, handleAppleSignIn } = useAppleAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    // If already authenticated, redirect to dashboard
    if (isAuthenticated && !isLoading) {
      const from = location.state?.from?.pathname || '/creators-portal/dashboard';
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, isLoading, navigate, location]);

  const handleAuthError = error => {
    console.error('Authentication error:', error);
    setError('Authentication failed. Please try again.');
    setTimeout(() => setError(null), 5000);
  };

  const handleGoogleLogin = async credentialResponse => {
    try {
      setGoogleLoading(true);
      const authResponse = await signInWithGoogle(credentialResponse.credential);

      if (authResponse.isOk) {
        const userData = {
          name: authResponse.given_name,
          email: authResponse.email,
        };
        login(authResponse.access_token, userData);
      } else {
        throw new Error('Failed to authenticate with backend');
      }
    } catch (error) {
      handleAuthError(error);
    } finally {
      setGoogleLoading(false);
    }
  };

  const onAppleSignIn = async () => {
    try {
      const response = await handleAppleSignIn();
      if (response) {
        if (response.accessToken) {
          const userData = { name: response.name };
          login(response.accessToken, userData);
        } else {
          throw new Error('No access token received');
        }
      }
    } catch (error) {
      handleAuthError(error);
    }
  };

  return (
    <div className='creators-portal'>
      <Navigation />

      <main className='creators-portal__main'>
        <section className='creators-portal__hero'>
          <div className='creators-portal__hero-background'>
            <img
              className='creators-portal__hero-image'
              src='https://lookaround-live.s3.amazonaws.com/76496bb683da4d5fb4e2f46b7eb8fe68_HiddenStairwaysGGPGreg.jpg'
              alt='Creator Portal Background'
            />
            <div className='creators-portal__hero-overlay' />
          </div>

          <div className='creators-portal__hero-content'>
            <h1 className='creators-portal__hero-title'>Transform Your Stories into Experiences</h1>
          </div>
        </section>

        <div className='creators-portal__auth'>
          <h2 className='creators-portal__auth-title'>Sign in to the Creators Portal</h2>

          {(error || appleError) && <div className='error-message mb-4'>{error || appleError?.message}</div>}

          <div className='creators-portal__auth-buttons'>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => handleAuthError('Google Sign In Failed')}
                useOneTap={false}
                disabled={googleLoading}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <AppleSignInButton onClick={onAppleSignIn} isLoading={appleLoading} disabled={!isAppleAuthReady} />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};
