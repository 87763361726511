import React from 'react';
import './ErrorScreen.css';
import { AlertTriangle } from 'lucide-react';

export const ErrorScreen = ({ message = 'An error occurred' }) => {
  return (
    <div className='error-screen'>
      <div className='error-screen__content'>
        <div className='error-screen__icon-wrapper'>
          <AlertTriangle className='error-screen__icon' size={48} />
        </div>
        <div className='error-screen__brand'>Something went wrong</div>
        <div className='error-screen__message'>{message}</div>
      </div>
    </div>
  );
};
