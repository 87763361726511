import React from 'react';
import './Footer.css';

export const Footer = () => (
  <footer className='footer'>
    <div className='container'>
      <div className='footer-content'>
        <span>© 2024 LookAround Inc.</span>
        <div>
          <a href='/terms-of-service' className='footer-link'>
            Terms of service
          </a>
        </div>
      </div>
    </div>
  </footer>
);
