import { useState, useEffect } from 'react';
import { LocalGuideTile, LocalGuideTilesList } from '../domain/guides/PublicGuides';
import { guideListForCreator } from '../api/creators/guide_operations';

interface GuideState {
  guides: LocalGuideTile[];
  selectedGuideId: string | null;
  loading: boolean;
  error: string | null;
}

interface GuideStateActions {
  fetchGuides: () => Promise<void>;
  selectGuide: (guideId: string | null) => void;
}

export const useGuideState = (): { state: GuideState; actions: GuideStateActions } => {
  const [state, setState] = useState<GuideState>({
    guides: [],
    selectedGuideId: null,
    loading: true,
    error: null,
  });

  const fetchGuides = async () => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      const response = await guideListForCreator();

      if (response.isOk && 'guides' in response) {
        const guidesList = response as LocalGuideTilesList;
        setState(prev => ({
          ...prev,
          guides: guidesList.guides,
          loading: false,
        }));
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: err instanceof Error ? err.message : 'Failed to load guides',
        loading: false,
      }));
    }
  };

  const selectGuide = (guideId: string | null) => {
    setState(prev => ({ ...prev, selectedGuideId: guideId }));
  };

  // Load guides when component mounts
  useEffect(() => {
    fetchGuides();
  }, []);

  return {
    state,
    actions: {
      fetchGuides,
      selectGuide,
    },
  };
};
