import React, { useState } from 'react';
import { Navigation } from '../../components/layout/Navigation/Navigation';
import { CreatorHero } from '../../components/sections/CreatorHero/CreatorHero';
import { CreatorBenefits } from '../../components/sections/CreatorBenefits/CreatorBenefits';
import { CreatorProcess } from '../../components/sections/CreatorProcess/CreatorProcess';
import { CreatorForm } from '../../components/sections/CreatorForm/CreatorForm';
import { Footer } from '../../components/layout/Footer/Footer';
import '../../styles/globals.css';

export const CreatorsPage = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <>
      <Navigation />
      <main>
        <CreatorHero />
        <CreatorBenefits />
        <CreatorProcess />
        <CreatorForm showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
      </main>
      <Footer />
    </>
  );
};
