import React from 'react';
import './CreatorHero.css';

export const CreatorHero = () => (
  <section className='hero'>
    <div className='container'>
      <div className='hero-content'>
        <div>
          <h1 className='hero-title'>Sign up as a Content Creator</h1>
          <p className='hero-description'>
            Join our community of storytellers and guides. Share your unique perspective of your neighborhood help users
            see things from a different lens.
          </p>
        </div>
        <div className='hero-image'>
          <img
            src='https://lookaround-live.s3.amazonaws.com/1a0fe4163b1e4807a7e4e985c1d405fa_ContentCreatorScreenshots.jpg'
            alt='Content Creator Screenshots'
          />
        </div>
      </div>
    </div>
  </section>
);
