import React from 'react';
import { APP_STORE_URL } from '../../../constants/theme';
import './Hero.css';

export const Hero = () => (
  <section className='hero'>
    <div className='container'>
      <div className='hero-content'>
        <div>
          <h1 className='hero-title'>Storytelling marketplace for your neighborhood</h1>
          <p className='hero-description'>
            Discover stories that make your neighborhood special. Develop a new perspective and become the go-to local
            storyteller.
          </p>
        </div>
        <div className='hero-app-preview'>
          <img
            src='https://lookaround-live.s3.amazonaws.com/c074c01056324bf7932daf999c352411_AppScreenshots.jpg'
            alt='LookAround App Interface'
          />
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <a href={APP_STORE_URL} target='_blank' rel='noopener noreferrer' className='app-store-button'>
          <img
            src='https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1725840000'
            alt='Download on the App Store'
            style={{ width: '156px', height: 'auto' }}
          />
        </a>
      </div>
    </div>
  </section>
);
