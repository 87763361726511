export const getGeolocation = (attemptsLeft = 1, bestResult = null) => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject({
        status: 'error',
        message: 'Geolocation not supported.',
      });
      return;
    }

    const options = {
      enableHighAccuracy: true, // Get the best possible results
      maximumAge: 0, // Force a fresh position
      // timeout: 5000, // Wait for 5 seconds before timing out, default infinity
      // cacheQuery: 'cacheKey', // Cache the location object on the device for faster retrieval
      // cacheMaxAge: 10000, // Accept cached positions up to 10 seconds old
      // cacheMaxAgeExtended: 20000, // Accept cached positions up to 20 seconds old for older versions of the Geolocation API
    };

    navigator.geolocation.getCurrentPosition(
      position => {
        const currentPosition = {
          status: 'success',
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy_meters: position.coords.accuracy,
          // These were not available as of the last try:
          // altitude: position.coords.altitude,
          // altitudeAccuracy: position.coords.altitudeAccuracy,
        };

        // Determine if the current position is better than the best result stored
        if (!bestResult || position.coords.accuracy < bestResult.accuracy) {
          bestResult = currentPosition;
        }

        // Resolve if accuracy is good enough or no more attempts left
        if (position.coords.accuracy <= 20 || attemptsLeft <= 1) {
          resolve(bestResult);
        } else {
          setTimeout(() => {
            // Recurse with one fewer attempt left
            getGeolocation(attemptsLeft - 1, bestResult)
              .then(resolve)
              .catch(reject);
          }, 2000); // delay next recursion by 2 seconds
        }
      },
      error => {
        if (attemptsLeft <= 1) {
          if (bestResult) {
            resolve(bestResult); // Resolve with the best result if available
          } else {
            reject({
              status: 'error',
              message: 'Failed to obtain accurate position after multiple attempts.',
            });
          }
        } else {
          // Attempt again ignoring the current error
          getGeolocation(attemptsLeft - 1, bestResult)
            .then(resolve)
            .catch(reject);
        }
      },
      options
    );
  });
};

export const LocationError = () => {
  return (
    <div>
      Error: Access to location denied. This might happen if your browser does not have access to your location and can
      be updated in your device settings. Then restart the browser and refresh. More details{' '}
      <a href='/enable-location' target='_blank' rel='noopener noreferrer'>
        here
      </a>
      .
    </div>
  );
};
