import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './GuidesMap.css';

// Fix for default markers
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

// Custom marker icon matching the brand color
const customIcon = new L.Icon({
  iconUrl:
    'data:image/svg+xml;base64,' +
    btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#026440" width="32" height="32">
      <path d="M12 0c-4.4 0-8 3.6-8 8c0 5.4 7 13.4 7.3 13.7c0.4 0.4 1 0.4 1.4 0c0.3-0.3 7.3-8.3 7.3-13.7c0-4.4-3.6-8-8-8zm0 12c-2.2 0-4-1.8-4-4s1.8-4 4-4s4 1.8 4 4s-1.8 4-4 4z"/>
    </svg>
  `),
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

// Separate component to handle bounds fitting
const BoundsHandler = ({ guides }) => {
  const map = useMap();

  useEffect(() => {
    if (!guides.length) return;

    const points = guides.map(guide => [guide.startLatitude, guide.startLongitude]);
    const bounds = L.latLngBounds(points);

    const paddedBounds = bounds.pad(0.2); // Using Leaflet's built-in pad method

    // Set minimum width to prevent excessive zoom on nearby points
    const minBoundsSizeInDegrees = 0.02; // Roughly 2km
    if (paddedBounds.getEast() - paddedBounds.getWest() < minBoundsSizeInDegrees) {
      const center = paddedBounds.getCenter();
      paddedBounds
        .extend([center.lat, center.lng + minBoundsSizeInDegrees / 2])
        .extend([center.lat, center.lng - minBoundsSizeInDegrees / 2]);
    }

    // Fit bounds with options for better mobile display
    map.fitBounds(paddedBounds, {
      padding: [30, 30], // Additional pixel padding
      maxZoom: 14, // Limit max zoom
      duration: 0.5, // Animation duration in seconds
    });

    // Handle window resize
    const handleResize = () => {
      map.invalidateSize();
      map.fitBounds(paddedBounds, {
        padding: [30, 30],
        maxZoom: 14,
        duration: 0,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [map, guides]);

  return null;
};

export const GuidesMap = ({ guides }) => {
  const validGuides = guides.filter(guide => guide.startLatitude && guide.startLongitude);

  if (validGuides.length === 0) {
    return null;
  }

  // Calculate initial center point between guides
  const initialCenter = validGuides.reduce(
    (acc, guide) => {
      acc[0] += guide.startLatitude / validGuides.length;
      acc[1] += guide.startLongitude / validGuides.length;
      return acc;
    },
    [0, 0]
  );

  return (
    <MapContainer center={initialCenter} zoom={12} className='guides-map' zoomAnimation={true} tap={false}>
      <TileLayer
        url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        maxZoom={19}
        minZoom={10}
        subdomains='abcd'
        detectRetina={true}
      />
      <BoundsHandler guides={validGuides} />
      {validGuides.map(guide => (
        <Marker key={guide.id} position={[guide.startLatitude, guide.startLongitude]} icon={customIcon}>
          <Popup>
            <div className='guide-popup'>
              <img src={guide.imageUrl} alt={guide.title} className='guide-popup__image' />
              <h3 className='guide-popup__title'>{guide.title}</h3>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};
