import React, { useState } from 'react';
import { Star } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Navigation } from '../../components/layout/Navigation/Navigation';
import { Footer } from '../../components/layout/Footer/Footer';
import { FeedbackFormData } from '../../domain/feedback/FeedbackForm';
import { submitFeedback } from '../../api/feedback/PostFeedbackForm';
import './EntityFeedbackForm.css';

interface StarRatingProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const StarRating: React.FC<StarRatingProps> = ({ value, onChange, disabled = false }) => {
  return (
    <div className='entity-feedback-star-container'>
      {[1, 2, 3, 4, 5].map(star => (
        <button
          key={star}
          onClick={() => !disabled && onChange(star)}
          disabled={disabled}
          type='button'
          className={`entity-feedback-star-button ${disabled ? 'entity-feedback-star-disabled' : ''}`}
        >
          <Star
            size={28}
            className={`entity-feedback-star ${
              star <= value ? 'entity-feedback-star-active' : 'entity-feedback-star-inactive'
            }`}
          />
        </button>
      ))}
    </div>
  );
};

const EntityFeedbackForm: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ratings, setRatings] = useState({
    overall: 0,
    content: 0,
    navigation: 0,
    value: 0,
  });
  const [recommend, setRecommend] = useState<boolean | null>(null);
  const [feedback, setFeedback] = useState('');

  if (!token) {
    return (
      <div className='entity-feedback-container'>
        <Navigation />
        <main className='entity-feedback-main'>
          <div className='entity-feedback-invalid'>
            <h2 className='entity-feedback-invalid-title'>Invalid Link</h2>
            <p className='entity-feedback-invalid-message'>This feedback link appears to be invalid.</p>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    const formData: FeedbackFormData = {
      token,
      overall_rating: ratings.overall,
      content_quality: ratings.content,
      ease_of_navigation: ratings.navigation,
      value_for_money: ratings.value,
      would_recommend: recommend ?? false,
      feedback,
    };

    try {
      const response = await submitFeedback(formData);

      if (response.success) {
        navigate('/feedback/thank-you');
      } else {
        setError(response.error || 'Failed to submit feedback');
      }
    } catch (err) {
      setError('An unexpected error occurred');
      console.error('Error submitting feedback:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isValid =
    ratings.overall > 0 && ratings.content > 0 && ratings.navigation > 0 && ratings.value > 0 && recommend !== null;

  return (
    <div className='entity-feedback-container'>
      <Navigation />

      <main className='entity-feedback-main'>
        <section className='entity-feedback-hero'>
          <div className='entity-feedback-hero-content'>
            <h1 className='entity-feedback-hero-title'>How was the tour?</h1>
          </div>
        </section>

        <section className='entity-feedback-form-section'>
          <div className='entity-feedback-form-wrapper'>
            {error && <div className='entity-feedback-error'>{error}</div>}

            <div className='entity-feedback-form-container'>
              <form onSubmit={handleSubmit} className='entity-feedback-form'>
                <div className='entity-feedback-field'>
                  <label className='entity-feedback-label'>Overall Experience</label>
                  <StarRating
                    value={ratings.overall}
                    onChange={value => setRatings(prev => ({ ...prev, overall: value }))}
                    disabled={isSubmitting}
                  />
                </div>

                <div className='entity-feedback-field'>
                  <label className='entity-feedback-label'>Content Quality</label>
                  <StarRating
                    value={ratings.content}
                    onChange={value => setRatings(prev => ({ ...prev, content: value }))}
                    disabled={isSubmitting}
                  />
                </div>

                <div className='entity-feedback-field'>
                  <label className='entity-feedback-label'>Ease of Navigation</label>
                  <StarRating
                    value={ratings.navigation}
                    onChange={value => setRatings(prev => ({ ...prev, navigation: value }))}
                    disabled={isSubmitting}
                  />
                </div>

                <div className='entity-feedback-field'>
                  <label className='entity-feedback-label'>Value for Money</label>
                  <StarRating
                    value={ratings.value}
                    onChange={value => setRatings(prev => ({ ...prev, value: value }))}
                    disabled={isSubmitting}
                  />
                </div>

                <div className='entity-feedback-field'>
                  <label className='entity-feedback-label'>Would you recommend this tour to a friend?</label>
                  <div className='entity-feedback-recommend'>
                    {[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ].map(option => (
                      <button
                        key={option.label}
                        type='button'
                        disabled={isSubmitting}
                        onClick={() => setRecommend(option.value)}
                        className={`entity-feedback-recommend-button ${
                          recommend === option.value ? 'entity-feedback-recommend-button-active' : ''
                        } ${isSubmitting ? 'entity-feedback-button-disabled' : ''}`}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>

                <div className='entity-feedback-field'>
                  <label className='entity-feedback-label'>Additional Feedback</label>
                  <textarea
                    value={feedback}
                    onChange={e => setFeedback(e.target.value)}
                    disabled={isSubmitting}
                    className='entity-feedback-textarea'
                    rows={4}
                    placeholder='Share your thoughts about the guide...'
                  />
                </div>

                <button
                  type='submit'
                  disabled={isSubmitting || !isValid}
                  className={`entity-feedback-submit ${
                    isSubmitting || !isValid ? 'entity-feedback-submit-disabled' : ''
                  }`}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
                </button>
              </form>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default EntityFeedbackForm;
