const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

const handleResponse = async response => {
  const statusCode = response.status;
  const isOk = response.ok;
  let responseData;

  try {
    responseData = await response.json();
  } catch (error) {
    responseData = {};
  }

  return { statusCode, isOk, ...responseData };
};

const createHeaders = (contentType, includeAuth = false) => {
  const headers = {
    'Content-Type': contentType,
  };
  if (includeAuth) {
    const token = getAccessToken();
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  }
  return headers;
};

export const asyncRequest = async (method, endpoint, data = null, options = {}) => {
  const { contentType = 'application/json', isProtected = false, isFormData = false } = options;

  try {
    const headers = createHeaders(contentType, isProtected);

    const config = isProtected ? { method, headers, credentials: 'include' } : { method, headers };

    if (data) {
      if (isFormData) {
        config.body = data;
        delete config.headers['Content-Type'];
      } else if (method !== 'GET') {
        config.body = JSON.stringify(data);
      }
    }

    const response = await fetch(endpoint, config);
    return handleResponse(response);
  } catch (error) {
    return { statusCode: 500, message: error.toString(), isOk: false };
  }
};

export const asyncGetRequest = (endpoint, isProtected = false) => asyncRequest('GET', endpoint, null, { isProtected });

export const asyncPostRequest = (endpoint, data = {}, isProtected = false) =>
  asyncRequest('POST', endpoint, data, { isProtected });

export const asyncPutRequest = (endpoint, data, options = {}) => asyncRequest('PUT', endpoint, data, options);

export const asyncPatchRequest = (endpoint, data, isProtected = false) =>
  asyncRequest('PATCH', endpoint, data, { isProtected });
